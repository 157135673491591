import * as React from "react";
import { FAQItem } from "../components/FAQItem";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";


const subtitle = <p><strong>Reviti</strong> Limited consumer privacy notice</p>
// markup
const IndexPage = () => {
  return (
    <Layout title={"Reviti - Home"}>
           <Header title={"Privacy Notice"} subtitle={subtitle} currentPage={"Privacy Notice"}/>
           <div className="container-padding relative flex flex-col md:flex-row my-30 terms-container"> 
<div className={"w-full md:w-2/3 flex flex-col"}>
      <p><span className="article-section-heading"><strong>Reviti</strong> Limited consumer privacy notice</span></p>
<p><strong>Reviti</strong> Limited ("<strong>Reviti</strong>" or "<strong>we</strong>") take privacy seriously. This notice tells you who we are, what information we collect about you, and what we do with it. We will use information about you only in accordance with applicable data protection laws. You can also click on the links in this notice for further information.</p>
<p>Please take a few minutes to read this notice and show it to anyone else connected to your policy.</p>
<p>Please also read our website terms of use and, before you purchase a policy from us and our partners, the terms and conditions of the policy if you intend to purchase. They provide more information about the way we do business and any restrictions on eligibility that may apply.</p>
<p> </p>
<p><span className="article-section-heading">Who are we?</span></p>
<p>We trade under the name <strong>Reviti</strong> and are an appointed representative of Resolution Compliance Limited which is authorised and regulated by the Financial Conduct Authority (FRN: 574048). We are a member of Philip Morris International.</p>
<p>Our details (name, address, etc.) will have been given to you separately at the time of (or to confirm) the collection of information about you, for example, in a notice on an app or a website, or in an e-mail, containing a link to this notice.</p>
<p><strong>Reviti</strong> is the data controller of any data you provide to <strong>Reviti</strong> or is otherwise collected through this website. This means that we determine the purposes and means of processing the data you provide us.</p>
<p>If you decide to purchase a policy through this website or through our contact centre then your data will be shared with Scottish Friendly Assurance Society Limited ("<strong>Scottish Friendly</strong>") for the purpose of underwriting your policy. They need your data to consider your application, administer your policy and to handle any claims made on your policy. Scottish Friendly will also be a data controller in respect of any data we provide to them for those purposes as well as any additional data they may need to collect about you. Please refer to Scottish Friendly's <a rel="noopener" href="https://www.scottishfriendly.co.uk/privacy-policy" target="_blank">privacy policy</a> for further information about how and why they process your data to underwrite and administer your policy.</p>
<p>If you have any questions about how we process your personal data, please contact us using the details found at the bottom of this notice.</p>
<p> </p>
<p><span className="article-section-heading">How do we collect information about you?</span></p>
<p>In this notice, we refer to all the methods by which you are in contact with us as “<strong>Reviti</strong> touchpoints”. <strong>Reviti</strong> touchpoints include both physical (for example, retail outlets and events, consumer contact centres), and digital (for example, apps and websites).</p>
<p>We may collect information about you in various ways.</p>
<p> </p>
<ul>
<li><strong>Information you provide us</strong>. You may provide us with information directly (for example, filling in a form, emailing or calling us). This will be how most of the data about you that we process is collected. You will be asked to fill in forms or provide information to our contact centre during the quote and application process. We will not be able to provide you with a quote or inform you of an offer from Scottish Friendly unless you answer the mandatory questions.</li>
</ul>
<p> </p>
<ul>
<li><strong>We collect personal information when you:</strong></li>
<li>Obtain a quote.</li>
<li>Apply for a policy.</li>
<li>Ask us a question.</li>
<li>Change your cover.</li>
<li>Update how we contact you with marketing.</li>
<li>Make a complaint.</li>
<li>Take part in targeted promotions.</li>
<li>Change ownership of a policy (for example, putting it in trust).</li>
<li>Take part in market research and surveys (for example, satisfaction surveys).</li>
</ul>
<p> </p>
<ul>
<li><strong>We may collect information automatically.</strong></li>
<li><strong>Typically this will happen when you:</strong></li>
<li>use a <strong>Reviti</strong> app or website or, where you have opted into this, where you have agreed to our using technologies to observe when you open e-mails or SMS messages.</li>
<li>If you exchange emails, telephone conversations or other electronic communications with us then our information technology systems and those of our outsourced partners will record details of those conversations, sometimes including their content.</li>
<li>If you respond to surveys that we may send to customers from time to time about the <strong>Reviti</strong> products you have.</li>
<li>communicate with us (for example, through a touchpoint, or social media platforms);</li>
<li>use <strong>Reviti</strong> touchpoints (for example, through tracking mechanisms (such as cookies and web beacons/pixels), where we use them, that you receive when you use the <strong>Reviti</strong> touchpoint or get an e-mail or SMS message from us);</li>
<li>use third party websites (for example, using technology similar to that described in the bullet above, that you receive when you visit a <strong>Reviti</strong> touchpoint or get an e-mail from us); or</li>
<li>make public posts on social media platforms that we follow (for example, so that we can understand public opinion, or respond to requests concerning <strong>Reviti</strong> products).</li>
<li>As mentioned above, we may collect information about you automatically through the use of cookies and similar tracking technologies (such as web beacons/pixels) that you receive when you visit digital <strong>Reviti</strong> touchpoints or get an electronic message from us. The specific cookies and other mechanisms used will depend on the <strong>Reviti</strong> touchpoint in question. To learn about these mechanisms used on a touchpoint, including how you can accept or refuse cookies, please see the information made available on or through that touchpoint. These mechanisms may include Google analytics cookies (see <a rel="noopener" href="http://www.google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>).</li>
<li>Our premises may have closed circuit TV systems for security and safety purposes. These may record you if you visit our premises.</li>
</ul>
<p>Where permitted by law, we may infer information about you from aggregated information we acquire from third parties. This may include, for example, statistical information about people in certain geographical areas.</p>
<p> </p>
<p>We may also collect information in other contexts made apparent to you at the time.</p>
<p> </p>
<ul>
<li><strong>We may acquire information from third parties. This includes:</strong></li>
<li>Your doctor or other relevant medical attendants.</li>
<li>Fraud prevention, enforcement or government agencies used to prevent or detect fraud or provide details to us about criminal convictions or offences.</li>
<li>HM Treasury and other authorities in relation to regulatory issues.</li>
<li>Publicly available sources. For example, information on social media platforms such as Facebook and Twitter or statistical information about the population in certain geographical areas.</li>
<li>Any price comparison websites (where we use such services) that you have used to find out about our policies.</li>
<li>Reputable marketing and advertising service providers.</li>
</ul>
<p> </p>
<p><span className="article-section-heading">What about information we collect from you about other people?</span></p>
<p>Occasionally we may need you to provide us with information about other people (for example, where we need to ask questions about the health of direct family members). Where you provide us with information relating to another person, you should make that person is aware of this privacy policy and ask them to read it. For the purposes of this privacy policy, any references to your personal information should be interpreted as also including information you provide us about others.</p>
<p> </p>
<p><span className="article-section-heading">For what purposes do we use information about you, and on what legal basis?</span></p>
<p>In this section, we describe the purposes for which we use personal information.</p>
<p> </p>
<ul>
<li><strong>Providing you with the service you have requested.</strong> We process your data to provide you with the service you have requested or otherwise engaged us in respect of for example: to provide a quote, allow you to save a quote; to allow you to return to a partially completed application; submit an application you have completed; trigger a premium adjustment; or put you in contact with Scottish Friendly regarding a claim or a complaint.</li>
</ul>
<p> </p>
<ul>
<li><strong>Automated decision making.</strong> We need your personal information when you apply so Scottish Friendly can decide whether to offer you a policy and, if so, on what terms. An automated underwriting engine is used as part of this process. The engine takes account of the information you have provided (including your age, whether you smoke, your answers to our other questions) along with the amount of cover you wish to obtain. We’ll make clear to you in the application for each policy whether automated underwriting is used. Please see below ("what rights and options do I have?") to find out more about your rights when automated decision-making processes are used.</li>
</ul>
<p> </p>
<ul>
<li><strong>In relation to your policy.</strong> Throughout the life of a policy, we will hold your personal information to enable us to administer the policy. We may also use the information to help us understand you as a customer and ensure that our products are appropriately priced.</li>
</ul>
<p> </p>
<ul>
<li><strong>Business and operational reasons.</strong> We process your information to otherwise operate, manage, develop and promote our business. We also process your information to operate, administer and improve our products, website, premises and other aspects of the way in which we conduct our operations.</li>
</ul>
<p> </p>
<ul>
<li><strong>Better understanding our customers.</strong> We process your data to better understand our customers and offer them products and services to accommodate their needs and requirements.</li>
</ul>
<p> </p>
<ul>
<li><strong>Comply with our legal and regulatory obligations and to defend legal claims.</strong> We and our contact centre provider may need to process your personal information to comply with our legal and regulatory obligations and to defend ourselves against legal claims, for example, to carry out sanctions, fraud and money laundering checks.</li>
</ul>
<p> </p>
<ul>
<li><strong>To keep you updated about your policy and features of your policy.</strong> We will use your personal data to keep you updated about the policy and comply with our regulatory obligation to inform you about existing benefits and related services and support you may be eligible for.</li>
</ul>
<p> </p>
<ul>
<li><strong>Direct marketing.</strong> When you apply for a policy we will ask you whether you would like to receive marketing material from us. Where you let us know you would like to receive this material we will use your details in order to send you such information. If you tell us that you do not want to receive marketing information we will not use your information for marketing reasons. You will receive your Life Insurance application quotes via email and we will still need to contact you from time to time for the purposes of servicing your policy. You can update your marketing preferences at any time by visiting our website and updating your preferences <a href="/unsubscribe/">using the link</a> provided. If we send you marketing emails you can also unsubscribe by clicking on the unsubscribe link or by contacting us.
<p className="p1">From time to time, we may also use data sourced from reputable marketing and advertising service providers for the purposes of conducting direct mail campaigns for which we rely upon the lawful basis of our legitimate interests. You can object to this processing at any time by contacting <strong>Reviti</strong> <a rel="noopener" href="/{localLink:umb://document/6cb16f87a0604a47af400b45284832b1}" target="_blank" title="Contact us">here.</a></p>
The information we collect can be categorised as follows:
<ul>
<li>information that confirms your identity;</li>
<li>information that confirms your contact information;</li>
<li>information that details your health and lifestyle choices, including presence of children;</li>
<li>information about homeowner status.</li>
</ul>
</li>
</ul>
<p> </p>
<ul>
<li><strong>Marketing and research.</strong> We may anonymise your personal information to process it for marketing and consumer research purposes.</li>
</ul>
<p> </p>
<ul>
<li><strong>Other.</strong> For other purposes that we notify you of, or will be clear from the context, at the point information about you is first collected.</li>
</ul>
<p> </p>
<p>Given the above purposes for which we use personal information, the legal basis for our use of information about you is one or more of the following:</p>
<p> </p>
<ul>
<li>consent. You are able to remove your consent at any time. You can do this by contacting <strong>Reviti</strong> <a rel="noopener" href="/{localLink:umb://document/6cb16f87a0604a47af400b45284832b1}" target="_blank" title="Contact us">here.</a></li>
<li>compliance with a legal obligation to which we are subject;</li>
<li>the performance of a contract to which you are a party; or</li>
<li>a legitimate business interest that is not overridden by your own interests in relation to that data.</li>
</ul>
<p> </p>
<p>In any other circumstances we will always ask for your consent before we process the information. This will be clear from the context.</p>
<p>In some instances, we may use information about you in ways that are not described above. Where this is the case we will provide a supplemental privacy notice that explains such use. You should read any supplemental notice in conjunction with this notice.</p>
<p>If you are uncertain as to our need for information that we request from you, or have concerns relating to the basis for processing we are relying on, then please <a href="/{localLink:umb://document/6cb16f87a0604a47af400b45284832b1}" title="Contact us">contact us</a> with your query.</p>
<p> </p>
<p><span className="article-section-heading">Do you process my sensitive personal information?</span></p>
<p>Yes, you will need to provide "special category" personal information to enable us to provide you with your quote or process your application for insurance. Special category personal data is afforded additional protection by data protection law. This data includes information about your health, including whether you are a smoker, for example, as well as information relating to your lifestyle and your family health history.</p>
<p>Unless we inform you otherwise, you should assume that we process this data for the purposes of arranging, underwriting or administering an insurance contract. For this reason, we do not require your consent. We may also retain a copy of any such information in order to comply with legal or regulatory obligations.</p>
<p> </p>
<p><span className="article-section-heading">Who do we share your information with, and for what purposes?</span></p>
<p>We may share information about you with:</p>
<p> </p>
<ul>
<li>Other companies within our group.</li>
<li>Resolution Compliance Limited and Scottish Friendly as well as Scottish Friendly's reinsurers for the purpose of arranging, underwriting and administering the policy.</li>
<li>To service providers who host our websites or other information technology systems or otherwise hold or process your information on our behalf, under strict conditions of confidentiality, data privacy and security.</li>
<li>To our contact centre</li>
<li>Carefully selected partners including Cavendish online Limited (in areas connected with our and other insurance products) so that they can contact you with offers that they think may interest you, in accordance with your marketing preferences and only with your consent where required.</li>
<li>To a person who takes over our business and assets, or relevant parts of them.</li>
<li>To our professional advisers.</li>
<li>In exceptional circumstances:</li>
<li>Regulators (e.g. the Financial Conduct Authority), government (e.g. HMRC) and law enforcement or fraud prevention agencies, as well as our professional advisers.</li>
<li>To competent regulatory, prosecuting and other governmental agencies, or litigation counterparties, in the applicable country or territory.</li>
</ul>
<p> </p>
<p><span className="article-section-heading">Where might information about you be sent?</span></p>
<p>When using information as described in this notice, information about you may be transferred either within or outside the United Kingdom where it was collected, including to a country or territory that may not have equivalent data protection standards. In all cases, the transfer will be:</p>
<p> </p>
<ul>
<li>on the basis of a <a rel="noopener" href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu" target="_blank" data-anchor="#dataprotectionincountriesoutsidetheeu">European Commission adequacy decision</a> (or equivalent decision in the UK);</li>
<li>subject to appropriate safeguards, for example the <a rel="noopener" href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank">EU Standard Contractual Clauses</a> ; or</li>
<li>necessary to discharge obligations under a contract between you and us (or the implementation of pre-contractual measures taken at your request) or for the conclusion or performance of a contract concluded in your interest between us and a third party.</li>
</ul>
<p> </p>
<p>In all cases, appropriate security measures for the protection of personal information will be applied in those countries or territories, in accordance with applicable data protection laws.</p>
<p> </p>
<p><span className="article-section-heading">How do we protect information about you?</span></p>
<p>We implement appropriate technical and organisational measures to protect personal information that we hold from unauthorised disclosure, misuse, alteration or destruction. Where appropriate, we use encryption and other technologies that can assist in securing the information you provide. We also require our service providers to comply with strict data privacy and security requirements.</p>
<p> </p>
<p><span className="article-section-heading">How long will information about you be kept?</span></p>
<p>We will retain information about you for the period necessary to fulfil the purposes for which the information was collected. After that, we will delete or anonymise it. The period will vary depending on the purposes for which the information was collected. Note that in some circumstances, you have the right to request us to delete or anonymise the information. Also, we are sometimes legally obliged to retain the information, for example, for tax and accounting purposes.</p>
<p>Where you have taken out a policy with us either through our website or our contact centre we and our contact centre (if relevant) will generally keep your information for a period of seven years following a claim or the end of your policy in line with the insurers policy, unless there is a requirement for us to retain the information (for example, a legal requirement, or where there are ongoing claims relating to your policy).</p>
<p>Where you have obtained a quote or started an application but not completed the purchase of a policy, we will generally keep your information for a period of 6 months before deleting. However, we will retain recordings of any calls between you and our contact centre for a period of 7 years.</p>
<p> </p>
<p><span className="article-section-heading">What rights and options do you have?</span></p>
<p>You may have some or all of the following rights in respect of information about you that we hold:</p>
<p> </p>
<ul>
<li>request us to give you access to it;</li>
<li>request us to rectify it, update it, or erase it;</li>
<li>request us to restrict our using it, in certain circumstances;</li>
<li>challenge a decision that has been made using automated decision making or otherwise request human intervention in an automated process;</li>
<li>object to our using it, in certain circumstances;</li>
<li>withdraw your consent to our using it;</li>
<li>data portability, in certain circumstances;</li>
<li>opt out from our using it for direct marketing; and</li>
<li>lodge a complaint with the supervisory authority.</li>
</ul>
<p> </p>
<p>We offer you easy ways to exercise these rights, such as “unsubscribe” links, or giving you a contact address, in messages you receive.</p>
<p>Right in respect of the information about you that we hold, with further detail</p>
<p>(note: certain legal limits to all these rights apply)</p>
<p> </p>
<ul>
<li><strong>To request us to give you access to it</strong></li>
<li>This is confirmation of:</li>
<li>whether or not we process information about you;</li>
<li>our name and contact details;</li>
<li>the purpose of the processing;</li>
<li>the categories of information concerned;</li>
<li>the categories of persons with whom we share the information and, where any such person is located outside the United Kingdom and the EEA and does not benefit from a UK adequacy decision, the appropriate safeguards for protecting the information;</li>
<li>(if we have it) the source of the information, if we did not collect it from you;</li>
<li>the existence of automated decision-making, including profiling, that produces legal effects concerning you, or significantly affects you in a similar way, and information about the logic involved, as well as the significance and the envisaged consequences of such processing for you; and</li>
<li>the criteria for determining the period for which we will store the information.</li>
</ul>
<p> </p>
<p>On your request we will provide you with a copy of the information about you that we use (provided this does not affect the rights and freedoms of others).</p>
<p> </p>
<ul>
<li><strong>To request us to rectify or update it</strong></li>
<li>This applies if the information we hold is inaccurate or incomplete.</li>
</ul>
<p> </p>
<ul>
<li><strong>To request us to erase it</strong></li>
<li>This applies if:</li>
<li>the information we hold is no longer necessary in relation to the purposes for which we use it;</li>
<li>we use the information on the basis of your consent and you withdraw your consent (in this case, we will remember not to contact you again, unless you tell us you want us to delete all information about you in which case we will respect your wishes);</li>
<li>we use the information on the basis of legitimate interest and we find that, following your objection, we do not have an overriding interest in continuing to use it;</li>
<li>the information was unlawfully obtained or used; or</li>
<li>to comply with a legal obligation</li>
</ul>
<p> </p>
<ul>
<li><strong>To request us to restrict our processing of it</strong></li>
<li>This right applies, temporarily while we look into your case, if you:</li>
<li>contest the accuracy of the information we use; or</li>
<li>have objected to our using the information on the basis of legitimate interest (if you make use of your right in these cases, we will tell you before we use the information again).</li>
<li>This right applies also if:</li>
<li>our use is unlawful and you oppose the erasure of the data; or</li>
<li>we no longer need the data, but you require it to establish a legal case.</li>
</ul>
<p> </p>
<ul>
<li><strong>To object to our processing it</strong></li>
<li>You have two rights here:</li>
<li>(i) if we use information about you for direct marketing: you can “opt out” (without the need to justify it) and we will comply with your request; and</li>
<li>(ii) if we use the information about you on the basis of legitimate interest for purposes other than direct marketing, you can object to our using it for those purposes, giving an explanation of your particular situation, and we will consider your objection.</li>
</ul>
<p> </p>
<ul>
<li><strong>To withdraw your consent to our using it</strong></li>
<li>This applies if the legal basis on which we use the information about you is consent based. These cases will be clear from the context.</li>
</ul>
<p> </p>
<ul>
<li><strong>To data portability</strong></li>
<li>If:</li>
<li>(i) you have provided data to us; and</li>
<li>(ii) we use that data, by automated means and on the basis either of your consent, or on the basis of discharging our contractual obligations to you,</li>
</ul>
<p> </p>
<p>then you have the right to receive the data back from us in a commonly used format, and the right to require us to transmit the data to someone else if it is technically feasible for us to do so. <strong>To lodge a complaint with the UK Information Commissioner</strong></p>
<p> </p>
<p><strong>The Information Commissioner’s </strong></p>
<p>Office Water Lane, Wycliffe House</p>
<p>Wilmslow - Cheshire SK9 5AF</p>
<p>Tel. +44 1625 545 700</p>
<p>email: <a href="mailto:casework@ico.org.uk">casework@ico.org.uk </a></p>
<p>Website: <a rel="noopener" href="https://ico.org.uk" target="_blank">https://ico.org.uk</a></p>
<p> </p>
<p><span className="article-section-heading">Who should you contact with questions?</span></p>
<p>If you have any questions, or wish to exercise any of your rights, you can find contact details for <strong>Reviti</strong> <a href="/{localLink:umb://document/6cb16f87a0604a47af400b45284832b1}" title="Contact us">here</a>. Contact details will also be given in any communications that <strong>Reviti</strong> sends you. The UK’s data protection authority is the Information Commissioners Office. You have a right to contact them with any questions or concerns (please see contact details above). If <strong>Reviti</strong> cannot resolve your questions or concerns, you also have the right to seek judicial remedy before a national court.</p>
<p> </p>
<p><span className="article-section-heading">Changes to this notice</span></p>
<p>We may update this notice (and any supplemental privacy notice) from time to time. Where the law requires it we will notify you of the changes; further, where the law requires it, we will also obtain your consent to the changes.</p>
<p> </p>
<p>Last modified [04/11/2021].</p>
<p> </p>
<p> </p>
<p> </p>
</div>
</div>
    </Layout>
  );
};

export default IndexPage;
